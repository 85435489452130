import '../utilities/array-from';
import '../utilities/matches';
import 'classlist.js';
import Flickity from 'flickity';
import objectFitImages from 'object-fit-images';

const heroSlider = document.getElementById( 'heroSlider' );
const slidePagerActiveClass = 'is-selected';
let slidePager;
let flkty;

function initFlickity( el ) {
	return new Flickity( el, {
		autoPlay: 4000,
		pauseAutoPlayOnHover: false,
		pageDots: false,
		prevNextButtons: false,
		setGallerySize: false,
		wrapAround: true,
	} );
}

function createPager( images ) {
	const pager = document.createElement( 'div' );
	const pagerItems = Array.from( images, image => `<button class="slidePager-item">${image.alt}</button>` );
	pager.classList.add( 'slidePager' );
	pager.appendChild( document.createRange().createContextualFragment( pagerItems.join( '' ) ) );
	return pager;
}

function setPagerActiveState() {
	const prevSelected = slidePager.querySelector( `.${slidePagerActiveClass}` );
	const selected = slidePager.children[flkty.selectedIndex];

	if ( prevSelected ) {
		prevSelected.classList.remove( slidePagerActiveClass );
	}

	selected.classList.add( slidePagerActiveClass );
}

function insertPager( images ) {
	slidePager = createPager( images );
	heroSlider.appendChild( slidePager );
	// Add timeout so transition is triggered on initial is-selected item.
	window.setTimeout( () => setPagerActiveState(), 50 );
}

function handlePagerNavigation( e ) {
	if ( !e.target.matches( '.slidePager-item' ) ) {
		return;
	}
	const buttons = Array.from( slidePager.children );
	const index = buttons.indexOf( e.target );
	// Need to stop & start player so that the autoPlay timer is reset.
	flkty.stopPlayer();
	flkty.select( index );
	flkty.playPlayer();
}

function polyfillObjectFit( images ) {
	objectFitImages( images );
}

function initEvents() {
	slidePager.addEventListener( 'click', handlePagerNavigation );
	flkty.on( 'select', setPagerActiveState );
}

function init() {
	if ( heroSlider ) {
		flkty = initFlickity( heroSlider );
		const slideImages = heroSlider.querySelectorAll( '.heroSlider-slide-image' );
		insertPager( slideImages );
		polyfillObjectFit( slideImages );
		initEvents();
	}
}

export default {
	init,
};
