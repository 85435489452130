import MapPin from './MapPin';

const maps = document.querySelectorAll( '.map' );

function getStyles() {
	return [
		{
			featureType: 'all',
			elementType: 'all',
			stylers: [
				{
					saturation: '-100',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
	];
}

function getCoords() {
	return {
		lat: parseFloat( this.getAttribute( 'data-lat' ) ),
		lng: parseFloat( this.getAttribute( 'data-lng' ) ),
	};
}

function renderMap( mapContainer ) {
	const pinContent = mapContainer.querySelector( '.mapPin-content' );
	const coords = new window.google.maps.LatLng( getCoords.apply( mapContainer ) );
	const args = {
		center: coords,
		fullscreenControl: false,
		mapTypeControl: false,
		mapTypeId: window.google.maps.MapTypeId.ROADMAP,
		panControl: false,
		scrollwheel: false,
		styles: getStyles(),
		zoomControl: true,
		zoom: 18,
		zoomControlOptions: {
			style: window.google.maps.ZoomControlStyle.SMALL,
		},
	};

	const map = new window.google.maps.Map( mapContainer, args );
	const pin = new MapPin.Pin( coords, pinContent );
	pin.setMap( map );
}

window.initVisioneerMaps = () => {
	MapPin.subClassOverlayView();

	for ( let i = 0; i < maps.length; i += 1 ) {
		renderMap( maps[i] );
	}
};

function googleMapsAsyncLoad() {
	const script = document.createElement( 'script' );
	script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${window.visioneerMapsKey}&callback=initVisioneerMaps`;
	document.body.appendChild( script );
}

function init() {
	if ( maps && window.visioneerMapsKey ) {
		googleMapsAsyncLoad();
	}
}

export default {
	init,
};
