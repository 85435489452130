import 'focus-visible';
import BlockLink from './components/BlockLink';
import HeroSlider from './components/HeroSlider';
import GoogleMaps from './components/GoogleMaps';
import Navbar from './components/Navbar';
import PreviewGrid from './components/PreviewGrid';
import ProjectIndex from './components/ProjectIndex';
import PreviewFilter from './components/PreviewFilter';
import ServiceWorker from './utilities/ServiceWorker';

BlockLink.init();
HeroSlider.init();
GoogleMaps.init();
Navbar.init();
PreviewGrid.init();
ProjectIndex.init();
PreviewFilter.init();
ServiceWorker.register();
