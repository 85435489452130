function Pin( position, content ) {
	this.position = position;

	const pin = document.createElement( 'div' );
	pin.classList.add( 'mapPin' );
	pin.appendChild( content );

	this.anchor = document.createElement( 'div' );
	this.anchor.appendChild( pin );
	this.anchor.classList.add( 'mapPinAnchor' );

	this.onAdd = function onAdd() {
		this.getPanes().floatPane.appendChild( this.anchor );
	};

	this.onRemove = function onRemove() {
		if ( this.anchor.parentElement ) {
			this.anchor.parentElement.removeChild( this.anchor );
		}
	};

	this.draw = function draw() {
		const divPosition = this.getProjection().fromLatLngToDivPixel( this.position );
		const display = Math.abs( divPosition.x ) < 4000 && Math.abs( divPosition.y ) < 4000 ? 'block' : 'none';

		if ( display === 'block' ) {
			this.anchor.style.left = `${divPosition.x}px`;
			this.anchor.style.top = `${divPosition.y}px`;
		}

		if ( this.anchor.style.display !== display ) {
			this.anchor.style.display = display;
		}
	};
}

function subClassOverlayView() {
	Pin.prototype = new window.google.maps.OverlayView();
}

export default {
	Pin,
	subClassOverlayView,
};
