import Tablesort from 'tablesort';

const projectIndex = {};
projectIndex.table = document.getElementById( 'projectIndex-table' );

function init() {
	if ( projectIndex.table ) {
		projectIndex.sort = new Tablesort( projectIndex.table );
	}
}

export default {
	init,
};
