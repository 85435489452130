const previewFilter = document.getElementById( 'previewFilter' );
const postPreviews = document.querySelectorAll( '.postPreview' );

function toggleDisabled( disabled, element ) {
	if ( disabled ) {
		element.setAttribute( 'disabled', '' );
	} else {
		element.removeAttribute( 'disabled' );
	}
}

function filterPreviews( e ) {
	const { projectFilter } = e.target.dataset;

	for ( let i = 0; i < postPreviews.length; i += 1 ) {
		const { projectType } = postPreviews[i].dataset;
		const ariaValue = projectFilter === 'all' || projectFilter === projectType;

		postPreviews[i].setAttribute( 'aria-selected', ariaValue );
		toggleDisabled( !ariaValue, postPreviews[i] );
	}
}

function toggleSelectedItem( e ) {
	const previewFilters = previewFilter.querySelectorAll( 'li' );
	for ( let i = 0; i < previewFilters.length; i += 1 ) {
		previewFilters[i].setAttribute( 'aria-checked', false );
	}
	e.target.setAttribute( 'aria-checked', true );
}

function handleClick( e ) {
	toggleSelectedItem( e );
	filterPreviews( e );
}

function initEvents() {
	if ( !previewFilter ) {
		return;
	}

	previewFilter.addEventListener( 'click', handleClick );
	// Run filters on pressing enter
	previewFilter.addEventListener( 'keydown', ( e ) => {
		if ( e.keyCode === 13 ) {
			handleClick( e );
		}
	} );
}

function init() {
	initEvents();
}

export default {
	init,
};
