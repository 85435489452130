import 'classlist.js';
import ScrollLock from 'body-scroll-lock';

const navbar = document.getElementById( 'navbar' );
const navbarBurger = document.getElementById( 'navbar-burger' );

function toggleItem( button, callback ) {
	const target = document.getElementById( button.getAttribute( 'aria-controls' ) );
	const isHidden = button.getAttribute( 'aria-expanded' ) === 'false';
	button.setAttribute( 'aria-expanded', isHidden );
	target.setAttribute( 'aria-hidden', !isHidden );

	if ( typeof callback === 'function' ) {
		callback.call( target, isHidden );
	}
}

function disableBodyScroll( isHidden ) {
	if ( isHidden ) {
		ScrollLock.disableBodyScroll( this );
	} else {
		ScrollLock.enableBodyScroll( this );
	}
}

function toggleNavbar() {
	navbar.classList.toggle( 'is-open' );
	toggleItem( navbarBurger, disableBodyScroll );
}

function initEvents() {
	navbarBurger.addEventListener( 'click', toggleNavbar );
}

function init() {
	initEvents();
}

export default {
	init,
};
