import 'classlist.js';
import 'element-closest';
import '../lib/matches';

const main = document.getElementById( 'main' );

function updateLocationOrOpenNewTab( e, link ) {
	if ( link ) {
		e.preventDefault();
		const isExternal = link.host !== window.location.host;

		if ( isExternal || e.ctrlKey || e.metaKey ) {
			const newWindow = window.open();
			newWindow.opener = null;
			newWindow.location = link.href;
		} else {
			window.location = link.href;
		}
	}
}

function makePostPreviewClickable( e ) {
	const blockLink = e.target.closest( '.blockLink' );

	if ( !blockLink ) {
		return;
	}

	if ( blockLink.hasAttribute( 'disabled' ) ) {
		e.preventDefault();
		return;
	}

	updateLocationOrOpenNewTab( e, blockLink.querySelector( '.blockLink-url' ) );
}

function init() {
	main.addEventListener( 'click', ( e ) => {
		makePostPreviewClickable( e );
	} );
}
export default {
	init,
};
