import Masonry from 'masonry-layout';

const PreviewGrid = {};
PreviewGrid.msnryContainer = document.getElementById( 'previewGrid' );

function initMasonry() {
	return new Masonry( PreviewGrid.msnryContainer, {
		itemSelector: '.previewGrid-item',
		columnWidth: '.previewGrid-item:not(.previewGrid-item--feature)',
	} );
}

PreviewGrid.init = function init() {
	if ( PreviewGrid.msnryContainer ) {
		PreviewGrid.msnry = initMasonry();
	}
};

export default PreviewGrid;
